.px-d-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
#Donate .bg-section-2 {
  background-image: url("../images//home/product-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}
#Donate .bg-section {
  /* background-image: url("../images//home/Plant-Left-2.webp"); */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: right bottom;
}
@media screen and (max-width: 600px) {
  #Donate .px-mb-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #Donate .pt-mb-1 {
    padding-top: 0.25rem !important;
  }
  #Donate .pt-mb-2 {
    padding-top: 0.5rem !important;
  }
  #Donate .pt-mb-3 {
    padding-top: 1rem !important;
  }
  #Donate .pt-mb-4 {
    padding-top: 1.5rem !important;
  }
  #Donate .pt-mb-5 {
    padding-top: 3rem !important;
  }
  #Donate .ps-mb-0 {
    padding-left: 0 !important;
  }
  #Donate .px-mb-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  #Donate .py-mb-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  #Donate .order-mb-2 {
    order: 2;
  }
  #Donate .order-mb-1 {
    order: 1;
  }
  #Donate .pt-mb-0 {
    padding-top: 0 !important;
  }
  #Donate section {
    overflow-x: hidden !important;
  }
}
