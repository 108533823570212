* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body {
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.text-pink {
  color: #bd1a78 !important;
}
.text-light-yellow {
  color: rgb(140, 113, 90);
}
.bg-light-pink {
  background-color: #ba6a991c !important;
}
p {
  font-size: 20px;
}
.hover12 figure {
  background: #0a0a0a;
}
.hover12 figure img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hover12 figure:hover img {
  opacity: 0.5;
}

@media screen and (max-width: 600px) {
  p {
    font-size: 18px;
  }
  .pt-mb-1 {
    padding-top: 0.25rem !important;
  }
  .pt-mb-2 {
    padding-top: 0.5rem !important;
  }
  .pt-mb-3 {
    padding-top: 1rem !important;
  }
  .pt-mb-4 {
    padding-top: 1.5rem !important;
  }
  .pt-mb-5 {
    padding-top: 3rem !important;
  }
  .px-mb-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
