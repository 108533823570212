.navbar-brand {
  width: 6rem !important;
}
.nav-item {
  padding: 0 0.7rem;
}
.nav-link {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #107f36 !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}
nav {
  position: fixed !important;
  width: 100%;
}
.skicky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: fadedownNav 0.8s ease-in-out 0s 1 normal none running;
  box-shadow: 2px 2px 10px #33333324;
  z-index: 999;
  background-color: #fff !important;
}
.swal-icon.swal-icon--success{
  overflow-y: hidden !important;
}
@keyframes fadedownNav {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.skicky-navbar .nav-link{
  color: green !important;
}

.nav-item .active {
  color: #bd1a78 !important;
}
.bg-link-pink{
  background-color: #ffeff8;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-image: url("https://theme.dsngrid.com/blackdsn/light/assets/img/bg-dot-light.png");
  background-color: #efefef;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 999;

}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 27px;
  color: black;
  display: block;
  transition: 0.3s;
}
.sidenav span {
  font-size: 14px;
  opacity: 1;
  color: rgba(black, black, black, 0.5);
}

.sidenav a:hover {
  color: black;
  scale: 1.1;
  transform: translateX(20px);
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-width: 600px) {
  .navbar-toggler:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
  .navbar .container {
    padding: 0 1rem !important;
  }
  .navbar-nav {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
  }
}
