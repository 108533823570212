#slider img {
  width: 250px !important;
  height: 250px !important;
  object-fit: cover;
}
#slider .slick-list {
  padding-left: 0.7rem !important;
}

.service-card-1 {
  transition: all 0.5s;
  width: 250px !important;
  height: 250px !important;
  position: relative;
  overflow: hidden;
}

.slick-prev:before,
.slick-next:before {
  color: #000000a4 !important;
}
.service-card-1:hover {
  background-color: #fff;
}

.service-data {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #000000a4;
  transition: all 0.3s;
  padding: 20px;
  transform: translateY(100px);
  opacity: 0;
}
.service-card-1:hover .service-data {
  opacity: 1;
  transform: translateY(0px);
  padding-top: 80px !important;
}

@media screen and (max-width: 600px) {
  #slider img,
  .service-card-1 {
    width: 100% !important;
    height: auto !important;
  }
  #slider .slick-list {
    padding-left: 0 !important;
  }
}
