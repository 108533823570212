:root {
  --primary-color: #bd1a78;
}

#ABOUT .user-1 img {
  border-top: 6px solid var(--primary-color);
  border-right: 6px solid var(--primary-color);
  border-bottom: 6px solid var(--primary-color);
  border-left: 6px solid var(--primary-color);
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
  border-bottom-left-radius: 200px;
}
.button {
  color: #fff;
  background: var(--primary-color);
  font-size: 18px;
  letter-spacing: 0.5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 3px solid var(--primary-color);
  border-right: 3px solid var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
  border-left: 3px solid var(--primary-color);
  padding-top: 9px;
  padding-right: 40px;
  padding-bottom: 9px;
  padding-left: 40px;
}
.button:hover {
  color: var(--primary-color);
  background: #fff;
  border-top-color: var(--primary-color);
  border-top-style: solid;
  border-right-color: var(--primary-color);
  border-right-style: solid;
  border-bottom-color: var(--primary-color);
  border-bottom-style: solid;
  border-left-color: var(--primary-color);
  border-left-style: solid;
}
#About .help {
  background-color: #ecf6f3 !important;
  /* background-image: url("../images//home/Plant-Left-2.webp"); */
  background-repeat: no-repeat;
  background-position: left top;
}
#About .bg-section {
  /* background-image: url("../images//home/Plant-Left-2.webp"); */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: right bottom;
}
#About .bg-section-2 {
  background-image: url("../images//home/product-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}
#About figure {
  margin-bottom: 0 !important;
}

.text-about {
  color: var(--primary-color);
  background: hsla(325, 76%, 42%, 0.178);
  border-color: var(--primary-color);
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  /* padding: 3rem; */
  font-size: 1.5rem;
  padding: 2rem 1.5rem;
}
.bg-light-pink {
  background-color: #ba6a991c;
}
@media screen and (max-width: 600px) {
  #About .pt-mb-0 {
    padding-top: 0 !important;
  }
  #About .px-mb-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #About .px-mb-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  #About .px-mb-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  #About .px-mb-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  #About .px-mb-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  #About .py-mb-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  #About .py-mb-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #About .pt-mb-4 {
    padding-top: 1.5rem !important;
  }
  #About .pb-mb-4 {
    padding-bottom: 1.5rem !important;
  }

  #About .pt-mb-5 {
    padding-top: 3rem !important;
  }
  #About .pb-mb-0 {
    padding-bottom: 0 !important;
  }
  #About .pb-mb-3 {
    padding-bottom: 1rem !important;
  }
  #About .text-mb-left {
    text-align: left !important;
  }
  #About section {
    overflow-x: hidden;
  }
  #About .justify-content-mb-start {
    justify-content: flex-start !important;
  }
  #About .button {
    color: #fff;
    background: var(--primary-color);
    font-size: 18px;
    letter-spacing: 0.5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 3px solid var(--primary-color);
    border-right: 3px solid var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
    border-left: 3px solid var(--primary-color);
    padding-top: 9px;
    padding-bottom: 9px;
  }
  #About .button:hover {
    color: var(--primary-color);
    background: #fff;
    border-top-color: var(--primary-color);
    border-top-style: solid;
    border-right-color: var(--primary-color);
    border-right-style: solid;
    border-bottom-color: var(--primary-color);
    border-bottom-style: solid;
    border-left-color: var(--primary-color);
    border-left-style: solid;
  }
}
