footer .footer-text {
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.7;
  font-weight: 400;
  overflow-wrap: break-word;
  text-align: center;
  color: white;
  font-size: 16px;
}

footer {
  color: rgb(176, 146, 120);
  /* font-weight: bold; */
}

footer .a {
  color: #bd1a78 !important;
}

.social-menu ul {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  padding: 0;
  margin: 0;
  /* transform: translate(-50%, -50%); */
  display: flex;
  justify-content: center;

  /* flex-direction: column; */
  /* gap:20px; */
}

.social-menu ul li {
  list-style: none;
  margin: 0 15px;
}

.social-menu ul li .fab {
  font-size: 30px;
  line-height: 60px;
  transition: 0.3s;
  color: #000;
}

.social-menu ul li .fab:hover {
  color: #fff;
}

.social-menu ul li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f9f6ed;
  text-align: center;
  transition: 0.6s;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}

.social-menu ul li a:hover {
  transform: translate(0, -10%);
  color: white !important;
}

.social-menu ul li:nth-child(1) a:hover {
  background-color: #0077b5;
}
.social-menu ul li:nth-child(2) a:hover {
  background-color: #e4405f;
}
.social-menu ul li:nth-child(3) a:hover {
  background-color: #0077b5;
}
.social-menu ul li:nth-child(4) a:hover {
  background-color: #000;
}

@media screen and (max-width: 600px) {
  footer .footer-text {
    color: white;
    font-size: 14px;
    line-height: 1.7em;
    padding: 0.5rem 0;
    text-align: start !important;
    font-size: 12px !important;
  }
  footer .ps-mb-1 {
    padding-left: 0.8rem !important;
  }
  footer .ps-mb-0 {
    padding-left: 0 !important;
  }

  footer .social-menu {
    justify-content: start !important;
  }
  footer .mb-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  footer .text-mb-start {
    text-align: start !important;
  }
  footer .mb-mb-0 {
    margin-bottom: 0 !important;
  }
  footer .section-details {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  footer .footer-text {
    padding: 0.5rem 1rem;
  }
  footer .ps-mb-1 {
    padding-left: 0.8rem !important;
  }
  footer .ps-mb-0 {
    padding-left: 0 !important;
  }

  footer .social-menu {
    justify-content: start !important;
  }
  footer .mb-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  footer .text-mb-start {
    text-align: start !important;
  }
  footer .mb-mb-0 {
    margin-bottom: 0 !important;
  }
  footer .section-details {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
