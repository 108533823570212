#home .button {
  color: #fff;
  background: var(--primary-color);
  font-size: 18px;
  letter-spacing: 0.5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 3px solid var(--primary-color);
  border-right: 3px solid var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
  border-left: 3px solid var(--primary-color);
  padding-top: 9px;
  padding-bottom: 9px;
}
#home .button:hover {
  color: var(--primary-color);
  background: #fff;
  border-top-color: var(--primary-color);
  border-top-style: solid;
  border-right-color: var(--primary-color);
  border-right-style: solid;
  border-bottom-color: var(--primary-color);
  border-bottom-style: solid;
  border-left-color: var(--primary-color);
  border-left-style: solid;
}
#card-blog-slider .image-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; /* Set width to auto to match the image width */
  height: auto; /* Take up the full height of the parent container */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
  transition: background-color 0.3s ease; /* Add transition effect for smoother appearance */
}

#card-blog-slider .image-overlay img {
  width: 100%; /* Ensure the image takes up the full width of the parent container */
  height: auto; /* Maintain aspect ratio */
}
#home .section-blog {
  /* background-image: url("../images//home/bg-4"); */
  /* background-color: #1b2125 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
/* ............................................................................................ */

.section-aboutus .heading h2 {
  font-family: Helvetica, arial, sans-serif;
}
.subscribe.input {
  color: rgb(71, 71, 71);
  border-style: solid;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 7px;
  padding-top: 23px;
  border-color: rgb(218, 218, 218);
  width: 100%;
}
.iqraemail h5 {
  letter-spacing: 1.5px;
  line-height: 1.25;
  color: rgb(27, 27, 27);
  font-weight: 700;
}
.iqraemail .email {
  letter-spacing: 1.5px;
  line-height: 1.25;
  color: #bd1a78 !important;
}
.iqraemail h1{
  font-size: 35px;
  font-weight: 500;
}
.padding-box{
  padding-top: 11rem;
  padding-bottom: 7rem;
}
#home .bg-light-pink {
  background-color: #ffb4e040 !important;
}
#home .sec-Orphans p {
  font-size: 18px !important;
}
#home .bg-darkest {
  background-color: #1b2125;
  color: white;
}
#home .box-icon-bordered {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
#home .box-icon-bordered-icon {
  color: #cf4a46 !important;
}
#home .section-help {
  background-color: #ecf6f3;
  background-image: url("../images//home/help-img.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: cover;
}
#home .Contactus {
  background-color: #ecf6f3;
  background-repeat: no-repeat;
  background-position: left top;
}
#home .bg-lightgreen {
  background-color: #ecf6f3 !important;
}
#home .arrow-animation {
  -webkit-animation: slideAni 1s ease-in-out infinite;
  animation: slideAni 1s ease-in-out infinite;
  /* display: inline-block;  */
}
#home .image-slider {
  background-color: #ecf6f3;
  /* background-repeat: no-repeat; */
  background-position: left top;
}

#home .box-icon-bordered i {
  font-size: 2.5rem;
}
#home .bg-section {
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: right top;
}
#home .bg-section1 {
  background: linear-gradient(rgba(136, 180, 78, 0.1), rgba(136, 180, 78, 0.1)),
    url(../images/home/product-bg.png) left bottom no-repeat;
}

@keyframes slideAni {
  0%,
  100% {
    transform: translate(0, 0);
    opacity: 0.1;
  }

  50% {
    transform: translate(10px, 0);
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
  .video-heigth{
    height: 100%;
  }
  #home .bg-section {
    background-position: right bottom !important;
  }
  .col-sm-6 {
    padding-top: 1rem;
    /* padding-bottom: 1rem; */
  }
  .justify-content-mb-center {
    justify-content: center !important;
  }
  .iqraemail p:nth-child(1) {
    text-align: justify;
    padding: 0 1.5rem;
  }
  #home .justify-content-mb-left {
    justify-content: flex-start !important;
  }
  #home .px-mb-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  #home .px-mb-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  #home.px-mb-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  #home .px-mb-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  #home .px-mb-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  #home .text-mb-left {
    text-align: left !important;
  }
  #home .pt-mb-0 {
    padding-top: 0 !important;
  }
  #home .pt-mb-5 {
    padding-top: 3rem !important;
  }
  #home .py-mb-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  #home .pt-mb-4 {
    padding-top: 1.5rem !important;
  }
  #home .py-mb-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #home .line-height-mb-3 {
    line-height: 3rem !important;
  }
  #home .py-mb-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  #home .py-mb-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .order-mb-1 {
    order: 1;
  }
  .order-mb-2 {
    order: 2;
  }
  #home section {
    overflow-x: hidden !important;
  }
  #home .box-icon-bordered {
    justify-content: flex-start !important;
  }
}
.subscribe_form {
  position: relative;
}
.subscribe_form input {
  background: #fff;
  border: 0;
  border-radius: 35px;
  font-size: 15px;
  font-weight: 600;
  height: 68px;
  padding: 5px 150px 5px 25px;
  width: 100%;
}
.subscribe_form button {
  background: #bd1a78;
  border: none;
  border-radius: 35px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 30px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 9px;
  transition: all .4s ease 0s;
  z-index: 1;
}
.border-pink{
  border-bottom: 2px solid #bd1a78;
}
.gallery-img{
  border: 2px solid #dbdbdb;
  padding: 2px;
}