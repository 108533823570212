.form-section .button {
  color: #fff;
  background: var(--primary-color);
  font-size: 18px;
  letter-spacing: 0.5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 3px solid var(--primary-color);
  border-right: 3px solid var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
  border-left: 3px solid var(--primary-color);
  padding-top: 9px;
  padding-bottom: 9px;
}

.form-section .button:hover {
  color: var(--primary-color);
  background: #fff;
  border-top-color: var(--primary-color);
  border-top-style: solid;
  border-right-color: var(--primary-color);
  border-right-style: solid;
  border-bottom-color: var(--primary-color);
  border-bottom-style: solid;
  border-left-color: var(--primary-color);
  border-left-style: solid;
}
/* .form-section input,
.form-section textarea {
  background-color: transparent;
} */

@media screen and (max-width: 600px) {
  .iqraemail .email{
    font-size: 15.5px !important;
  }
  #contact .pt-mb-0 {
    padding-top: 0 !important;
  }
  #contact .ps-mb-0 {
    padding-left: 0 !important;
  }
  #contact .ps-mb-0 {
    padding-left: 0 !important;
  }
  #contact .px-mb-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #contact .px-mb-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  #contact .px-mb-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  #contact .px-mb-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  #contact .px-mb-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  #contact .pt-mb-5 {
    padding-top: 3rem !important;
  }
  #contact .pb-mb-0 {
    padding-bottom: 0 !important;
  }
  #contact .pb-mb-3 {
    padding-bottom: 1rem !important;
  }
  #contact .text-mb-left {
    text-align: left !important;
  }
  #contact .py-mb-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  #contact .py-mb-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #contact .py-mb-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;

  }
  #contact .icon-section .row {
    gap: 20px;
  }
}
