.carousel-item img {
  width: 100%;
  height: 95vh !important;
  object-fit: cover;
  object-position: top;
  opacity: 0.6;
}
.carousel-item{
  background-color: black;
}
.bd-banner-text{
  position: absolute;
  top: 45%;
  width: 100%;
  text-align: right;
}
.bd-banner-text h3{
  color: white ;
  font-size: 50px;
  font-weight: 550;
}
.bd-banner-text p{
  color: white;
  font-size: 20px;
}


@media screen and (max-width: 600px) {
  .carousel-item img {
    width: 100%;
    height: 65vh !important;
    object-fit: cover;
    object-position: top;
  }
  .bd-banner-text h3{
    font-size: 20px;
  }
}
